"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const locale_1 = require("~/services/locale");
/**
 * Formulaire de géolocalisation.
 * @Constructor
 */
class GeocoderForm {
    constructor() {
        this.$el = $(`
      <form action="#geocode" onsubmit="return false;" class="jgnBasicControl">
        <div>
          <input id="geocoder-address" class="geocoder-address with-example" name="address" size="60"
            title="${(0, locale_1.t)('geocoder.help')}"
            placeholder="${(0, locale_1.t)('geocoder.help')}" />
            <input class="geocoder-submit" type="submit" value="" />
        </div>
      </form>`);
        // Ne rien faire à la soumission du formulaire
        this.$el.on("submit", e => e.preventDefault());
    }
    /**
     * Retourne l'adresse saisie dans le formulaire.
     */
    getAddress() {
        const $address = $('#geocoder-address', this.$el);
        var address = $address.val();
        if (address == $address.attr('title')) {
            return '';
        }
        return address;
    }
    ;
}
exports.default = GeocoderForm;
