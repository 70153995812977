"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings_1 = require("~/settings");
const locale_1 = require("~/services/locale");
require("./Buttons.scss");
/**
 * Controleur.
 */
class Buttons extends OpenLayers.Control {
    draw(px) {
        const buttons = [
            {
                title: (0, locale_1.t)('buttons.zoomIn'),
                src: 'img/round_plus.png',
                clazz: 'ZoomIn',
                trigger: () => {
                    this.map.zoomIn();
                }
            },
            {
                title: (0, locale_1.t)('buttons.zoomExtent'),
                src: 'img/globe_3.png',
                clazz: 'ZoomExtent',
                trigger: () => {
                    var layer = this.map.baseLayer;
                    this.map.zoomToExtent(layer.maxExtent);
                }
            },
            {
                title: (0, locale_1.t)('buttons.zoomOut'),
                src: 'img/round_minus.png',
                clazz: 'ZoomOut',
                trigger: () => {
                    this.map.zoomOut();
                }
            } /*,
                  {
                      title: "Centrer l'écran sur la zone d'impression",
                      src: 'img/cursor_drag_arrow.png',
                      clazz: 'PrintCenter',
                      trigger: () => {
                          if (this.printPreview == null) {
                              this.printPreview = this.map.getControlsByClass('JGN.Control.PrintPreview')[0];
                          }
                          var bounds = this.printPreview.getBounds();
                          this.map.zoomToExtent(bounds);
                      }
                  },
                  {
                      title: "Centrer l'impression sur ce qui apparaît à l'écran",
                      src: 'img/target.png',
                      clazz: 'ScreenCenter',
                      trigger: () => {
                          if (this.printPreview == null) {
                              this.printPreview = this.map.getControlsByClass('JGN.Control.PrintPreview')[0];
                          }
                          var center = this.map.getCenter();
                          this.printPreview.updatePrintBox(center);
                      }
                  }*/
        ];
        for (const button of buttons) {
            const control = this.buildControl(button);
            this.map.addControl(control);
        }
        return null;
    }
    buildControl(button) {
        const control = new OpenLayers.Control({
            displayClass: 'jgnButton jgnControlButton ' + button.clazz,
            draw(px) {
                OpenLayers.Control.prototype.draw.call(this, px);
                const $img = $(`<img title="${button.title}" src="${settings_1.settings.baseUrl + button.src}"/>`);
                $img.on("click", button.trigger.bind(this));
                this.div.appendChild($img.get(0));
                return this.div;
            },
        });
        return control;
    }
}
exports.default = Buttons;
Object.defineProperties(Buttons.prototype, {
    CLASS_NAME: {
        value: "JGN.Control.Buttons"
    }
});
