"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const locale_1 = require("~/services/locale");
const settings_1 = require("~/settings");
const utils_1 = require("./utils");
/**
 * View pour remplacer le LayerSwitcher par défaut d'openlayers.
 *
 * D'un point de vue html, c'est un formulaire. Il est 'submit' lorsque l'utilisateur
 * clic sur un layer pour le sélectionner. Ce layer sera alors accessible via le paramètre
 * 'selectedLayer'.
 *
 * On s'inspire de
 * http://www.htmldrive.net/items/show/749/Image-Select-Elements-with-jQuery-and-CSS3
 *
 * @constructor
 */
class BaseLayerSelect {
    constructor() {
        this.$el = $('<form class="baseLayerSelect"/>');
        // Ne rien faire à la soumission du formulaire
        this.$el.on("submit", e => e.preventDefault());
        // On ajoute l'image
        const $selectBox = $(`
      <div class="jgnButton selectBox" title="${(0, locale_1.t)('baseLayerSelect.help')}">
        <img src="${settings_1.settings.baseUrl}img/layers.png"/>
      </div>`);
        this.$el.append($selectBox);
        // Affiche le choix sélectionné
        const $choice = $('<div id="choice">&nbsp;</div>');
        $selectBox.append($choice);
        // Panel
        const $panel = $('<div class="jgnPanel"/>');
        this.$el.append($panel.hide());
        // Affiche les choix disponibles
        const $dropDown = $('<ul class="dropDown"/>');
        $panel.append($dropDown);
        // Mise en place des fonctionnalités / animations
        (0, utils_1.buildButtonPanel)($selectBox, $panel);
    }
    get $dropDown() {
        return $('.dropDown', this.$el);
    }
    get $choice() {
        return $("#choice", this.$el);
    }
    /**
     * Sera appelé par le contrôleur pour mettre à jour le composant
     *
     * @param layers: la liste des layers sélectionnables
     * @param selectedLayer: le layer actuellement sélectionné
     */
    redraw(layers, selectedLayer) {
        console.log("Redraw BaseLayerSelect");
        // Clear out previous layers
        $('li', this.$dropDown).remove();
        // On change le titre
        if (selectedLayer) {
            this.$choice.html(selectedLayer.name);
        }
        // On crée les éléments permettant de sélectionner chacun des layers
        console.log("display", { layers });
        for (const l of layers) {
            const layer = l;
            if (layer.isBaseLayer) { // Je me fiche des overlays ici...
                var $item = $(`
          <li>
            <img src="${settings_1.settings.baseUrl + (layer.imagePath || 'img/layer-default.jpg')}"/>',
            <span>${layer.name}</span>
          </li>`);
                if (layer == selectedLayer) {
                    $item.addClass('selected');
                }
                //$item.click(layer, this.onLayerSelected.bind(this));
                $item.on("click", layer, e => this.onLayerSelected(e));
                this.$dropDown.append($item);
            }
        }
    }
    /**
     * Sera appelé lorsque l'utilisateur clic sur un layer pour le sélectionner.
     *
     * @param event: le clic de la souris correspondant au choix du layer
     */
    onLayerSelected(event) {
        var layer = event.data;
        this.$choice.html(layer.name);
        this.$dropDown.trigger('hide');
        this.selectedLayer = layer;
        this.$el.trigger("submit");
    }
}
exports.default = BaseLayerSelect;
